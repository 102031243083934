export const environment = {
  production: false,
  hmr: false,
  api: {
    url: 'https://dev-journey.vivasmart.com.mx/v1',
  },
  images: {
    icons: 'https://dev-journey.vivasmart.com.mx/uploads/',
    url: 'https://backend-filestorage.s3-us-west-1.amazonaws.com/vivasmart-journey/',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDqsPbva_cZiK1XMS-GOEi1UhnvNhv7GbI',
    authDomain: 'vivasmartjourneyandroid.firebaseapp.com',
    databaseURL: 'https://vivasmartjourneyandroid.firebaseio.com',
    projectId: 'vivasmartjourneyandroid',
    storageBucket: 'vivasmartjourneyandroid.appspot.com',
    messagingSenderId: '989528849200',
    appId: '1:989528849200:android:3d23bcf27ac2a6222b26f5',
  },
};
